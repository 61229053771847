.space-preview {
  margin-left: 270px;
}
.design-drawer {
  background: #f9f9f9;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 270px;
  border-right: 1px solid #eee;
}
.design-drawer .content {
  padding: 20px;
  overflow-x: auto;
  position: relative;
  height: 100%;
}
.design-drawer h2 {
  font-size: 16px;
}
.post-preview {
  padding: 80px 40px 40px 40px;
  height: 100vh;
}
.post-preview h1 {
  font-size: 30px;
  max-width: 650px;
  margin-bottom: 15px;
  margin-right: auto;
  margin-left: auto;
  color: #333;
}
.post-preview h1,
.post-preview h2,
.post-preview h3,
.post-preview h4,
.post-preview h5 {
  line-height: 1.4;
}
.post-preview p {
  font-family: 'PT Serif', serif;
  font-size: 19px;
  line-height: 1.7;
  min-height: 30px;
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;
}
.post-preview a {
  text-decoration: underline;
}
.settings-group {
  margin: 0 0 30px 0;
}
.close-link a {
  color: #333;
}
.close-link {
  margin-bottom: 20px;
  font-size: 16px;
  padding-bottom: 10px;
}
.field {
  margin: 10px 0 0 0;
}
.section-item {
  border: 1px solid #ddd;
  padding: 10px;
  margin: 0 0 10px 0;
  position: relative;
}
.delete {
  position: absolute;
  cursor: pointer;
  right: 10px;
}
.color-swatch {
  height: 27px;
  width: 27px;
  border-radius: 50%;
  border: 1px solid #ddd;
  cursor: pointer;
}
.chrome-picker {
  box-shadow: none !important;
}